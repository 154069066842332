import React, { useState, useEffect } from 'react';
import { Alert, AlertDescription } from './components/ui/alert';
import Keyboard from './components/Keyboard';
import GuessBoard from './components/GuessBoard';
import Timer from './components/Timer';
import ScoreDisplay from './components/ScoreDisplay';
import { useMathGame } from './hooks/useMathGame';
import { useKeyboardInput } from './hooks/useKeyboardInput';
import { motion } from 'framer-motion';
import HowToPlayModal from './components/HowToPlayModal';
import GameOverModal from './components/GameOverModal';

const MathFunctionGame: React.FC = () => {
  const {
    guesses,
    guessResults,
    inputHints,
    outputHints,
    currentGuess,
    message,
    score,
    timeLeft,
    handleInput,
    handleDelete,
    handleSubmit,
    blockedKeys,
    correctFunction,
    gameWon,
    resetGame
  } = useMathGame();

  const [isHowToPlayOpen, setIsHowToPlayOpen] = useState(false);
  const [isGameOver, setIsGameOver] = useState(false);
  const [gameResult, setGameResult] = useState({ isWinner: false, guessesTaken: 0 });

  useKeyboardInput(handleInput, handleDelete, handleSubmit);

  useEffect(() => {
    if (gameWon || currentGuess >= 6) {
      setIsGameOver(true);
      setGameResult({
        isWinner: gameWon,
        guessesTaken: gameWon ? currentGuess : 6
      });
    }
  }, [gameWon, currentGuess]);

  const handlePlayAgain = () => {
    resetGame();
    setIsGameOver(false);
  };

  if (!correctFunction) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-primary"></div>
      </div>
    );
  }

  return (
    <motion.div 
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
      className="flex flex-col items-center space-y-4 p-4 bg-background min-h-screen font-body text-text"
    >
      <h1 className="text-4xl font-heading font-bold mb-4 text-primary">Mathdle</h1>
      <p className="text-lg mb-2">Guess the mathematical function!</p>

      <button 
        onClick={() => setIsHowToPlayOpen(true)}
        className="bg-accent text-white px-4 py-2 rounded-lg hover:bg-accent-light transition-colors"
      >
        How to Play
      </button>

      <GuessBoard inputHints={inputHints} outputHints={outputHints} currentGuess={currentGuess} guessResults={guessResults} guesses={guesses} />

      <div className="mt-4">
        <Keyboard 
          onKeyPress={handleInput} 
          onDelete={handleDelete} 
          onSubmit={handleSubmit} 
          blockedKeys={blockedKeys}
        />
      </div>

      <div className="flex space-x-4 mt-4">
        <Timer timeLeft={timeLeft} />
        <ScoreDisplay score={score} />
      </div>

      {message && (
        <Alert className="bg-secondary text-text p-4 rounded-lg mt-4">
          <AlertDescription>{message}</AlertDescription>
        </Alert>
      )}

      <GameOverModal
        isOpen={isGameOver}
        onClose={() => setIsGameOver(false)}
        isWinner={gameResult.isWinner}
        correctFunction={correctFunction}
        guessesTaken={gameResult.guessesTaken}
        onPlayAgain={handlePlayAgain}
      />

      <HowToPlayModal isOpen={isHowToPlayOpen} onClose={() => setIsHowToPlayOpen(false)} />
    </motion.div>
  );
};

export default MathFunctionGame;