import React from 'react';

const PrivacyPolicy: React.FC = () => {
  return (
    <div className="container mx-auto px-4 py-8 max-w-3xl">
      <h1 className="text-3xl font-bold mb-6">Privacy Policy</h1>
      
      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">Information We Collect</h2>
        <p className="mb-4">
          We collect information that your browser sends whenever you visit our website. This may include:
        </p>
        <ul className="list-disc pl-8 mb-4">
          <li>Browser type and version</li>
          <li>Time and date of your visit</li>
          <li>Game statistics and scores</li>
          <li>Device information</li>
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">How We Use Your Information</h2>
        <p className="mb-4">
          We use the collected information for:
        </p>
        <ul className="list-disc pl-8 mb-4">
          <li>Improving our website and services</li>
          <li>Analyzing usage patterns</li>
          <li>Personalizing user experience</li>
          <li>Displaying relevant advertisements</li>
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">Advertising</h2>
        <p className="mb-4">
          We use Google AdSense to display advertisements. Google AdSense uses cookies to serve ads based on your prior visits
          to our website or other websites. You can opt out of personalized advertising by visiting
          <a href="https://www.google.com/settings/ads" className="text-primary hover:underline ml-1" target="_blank" rel="noopener noreferrer">
            Google's Ads Settings
          </a>.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">Cookies</h2>
        <p className="mb-4">
          We use cookies to improve your experience on our website. You can choose to disable cookies through your browser
          settings, but this may affect the functionality of our website.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">Contact Us</h2>
        <p className="mb-4">
          If you have any questions about our Privacy Policy, please contact us.
        </p>
      </section>
    </div>
  );
};

export default PrivacyPolicy; 