import React from 'react';
import './App.css';
import MathFunctionGame from './MathFunctionGame';
import Navbar from './components/Navbar';
import PrivacyPolicy from './components/PrivacyPolicy';

function App() {
  return (
    <div className="App">
      <Navbar />
      <MathFunctionGame />
      <div id="privacy">
        <PrivacyPolicy />
      </div>
    </div>
  );
}

export default App;
