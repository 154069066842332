import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';

interface GameOverModalProps {
  isOpen: boolean;
  onClose: () => void;
  isWinner: boolean;
  correctFunction: string;
  guessesTaken: number;
  onPlayAgain: () => void;
}

const GameOverModal: React.FC<GameOverModalProps> = ({ 
  isOpen, 
  onClose,
  isWinner,
  correctFunction,
  guessesTaken,
  onPlayAgain
}) => {
  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
          onClick={onClose}
        >
          <motion.div
            initial={{ scale: 0.8 }}
            animate={{ scale: 1 }}
            exit={{ scale: 0.8 }}
            className="bg-background p-8 rounded-lg text-center max-w-md w-full mx-4"
            onClick={(e) => e.stopPropagation()}
          >
            <h2 className="text-3xl font-bold mb-4">
              {isWinner ? '🎉 Victory! 🎉' : '😞 Game Over'}
            </h2>
            
            <p className="text-lg mb-4">
              {isWinner 
                ? `You guessed the function in ${guessesTaken} ${guessesTaken === 1 ? 'try' : 'tries'}!`
                : 'Better luck next time!'}
            </p>
            
            <div className="bg-secondary p-4 rounded-lg mb-6">
              <p className="font-mono text-sm">
                The function was: {correctFunction}
              </p>
            </div>

            <button
              className="bg-primary text-white px-6 py-3 rounded-lg hover:bg-primary-dark transition-colors text-lg"
              onClick={onPlayAgain}
            >
              Play Again
            </button>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default GameOverModal; 