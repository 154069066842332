/* eslint no-eval: 0 */
// hooks/useMathGame.ts
import { useState, useCallback, useEffect } from 'react';

export const useMathGame = () => {
    const [guesses, setGuesses] = useState<string[][]>(Array(6).fill(Array(6).fill('')));
    const [currentGuess, setCurrentGuess] = useState<number>(0);
    const [message, setMessage] = useState<string>('');
    const [, setPressedKey] = useState<string | null>(null);
    const [, setLastInputted] = useState<{ row: number; col: number }>({ row: -1, col: -1 });
    const [inputHints, setInputHints] = useState<string[]>([]);
    const [outputHints, setOutputHints] = useState<string[]>([]);
    const [correctFunction, setCorrectFunction] = useState<string>('');
    const [guessResults, setGuessResults] = useState<string[][]>(Array(6).fill(Array(6).fill('')));
    const [currentHintIndex, setCurrentHintIndex] = useState<number>(0);
    const [blockedKeys, setBlockedKeys] = useState<Set<string>>(new Set());
    const [score] = useState(0);
    const [timeLeft, setTimeLeft] = useState(600); // 10 minutes
    const [gameWon, setGameWon] = useState(false);

    const handleInput = useCallback((key: string): void => {
        if (currentGuess >= 6) return;

        setPressedKey(key);
        setTimeout(() => setPressedKey(null), 100);

        setGuesses(prevGuesses => {
            const newGuesses = prevGuesses.map(row => [...row]);
            const currentRow = newGuesses[currentGuess];
            const emptyIndex = currentRow.findIndex(cell => cell === '');

            if (emptyIndex !== -1) {
                currentRow[emptyIndex] = key;
                setLastInputted({ row: currentGuess, col: emptyIndex });
            }

            return newGuesses;
        });
    }, [currentGuess]);

    const handleDelete = useCallback((): void => {
        if (currentGuess >= 6) return;

        setPressedKey('delete');
        setTimeout(() => setPressedKey(null), 100);

        setGuesses(prevGuesses => {
            const newGuesses = prevGuesses.map(row => [...row]);
            const currentRow = newGuesses[currentGuess];
            const lastFilledIndex = currentRow.map(cell => cell !== '').lastIndexOf(true);

            if (lastFilledIndex !== -1) {
                currentRow[lastFilledIndex] = '';
                setLastInputted({ row: currentGuess, col: lastFilledIndex });
            }

            return newGuesses;
        });
    }, [currentGuess]);

    const handleSubmit = useCallback((): void => {
        if (currentGuess >= 6) return;

        setPressedKey('submit');
        setTimeout(() => setPressedKey(null), 100);

        const expression = guesses[currentGuess].join('');
        const correct = correctFunction.split('');
        const results = guesses[currentGuess].map((char, index) => {
            if (char === correct[index]) return 'correct';
            if (correct.includes(char)) return 'present';
            return 'absent';
        });

        setGuessResults(prev => {
            const newResults = [...prev];
            newResults[currentGuess] = results;
            return newResults;
        });

        // Update blocked keys
        const newBlockedKeys = new Set(blockedKeys);
        results.forEach((result, index) => {
            if (result === 'absent') {
                newBlockedKeys.add(guesses[currentGuess][index]);
            }
        });
        setBlockedKeys(newBlockedKeys);

        if (expression === correctFunction) {
            setMessage(`Correct! The function was ${correctFunction}`);
            setCurrentGuess(6); // End the game
            setGameWon(true);
        } else {
            setMessage('Incorrect. Try again!');
            setCurrentGuess(prev => prev + 1);
            // Reveal next hint
            if (currentHintIndex < inputHints.length - 1) {
                setCurrentHintIndex(prev => prev + 1);
            }
        }

        if (currentGuess === 5) {
            setMessage(`Game over! The correct function was ${correctFunction}`);
        }
    }, [currentGuess, guesses, correctFunction, currentHintIndex, inputHints.length, blockedKeys]);

    useEffect(() => {
        if (timeLeft > 0 && currentGuess < 6) {
            const timer = setTimeout(() => setTimeLeft(timeLeft - 1), 1000);
            return () => clearTimeout(timer);
        } else if (timeLeft === 0) {
            setMessage("Time's up! Game over.");
            setCurrentGuess(6);
        }
    }, [timeLeft, currentGuess]);

    const generateAllHints = useCallback((func: string): void => {
        const newInputHints: string[] = ["0", "1", "2", "5", "10", "-1"];
        const newOutputHints: string[] = newInputHints.map(input =>
            evaluateFunction(func, parseInt(input, 10)).toString()
        );
        setInputHints(newInputHints);
        setOutputHints(newOutputHints);
        setCurrentHintIndex(0);
    }, []);

    const fetchDailyProblem = useCallback(async () => {
        try {
            const response = await fetch('https://api.mathdle.net/api/daily');
            const data = await response.json();
            setCorrectFunction(data.function);
            setInputHints(data.inputs);
            setOutputHints(data.outputs);
            setCurrentHintIndex(0);
        } catch (error) {
            console.error('Failed to fetch daily problem:', error);
            // Fallback to a default function if API fails
            const defaultFunction = '2x+1+0';
            setCorrectFunction(defaultFunction);
            generateAllHints(defaultFunction);
        }
    }, []);

    useEffect(() => {
        fetchDailyProblem();
    }, [fetchDailyProblem]);

    const evaluateFunction = (func: string, x: number): number => {
        // Handle implicit multiplication
        let preparedFunc = func.replace(/(\d+)x/g, (match, p1) => `${p1}*x`);
        // Replace x with its value
        preparedFunc = preparedFunc.replace(/x/g, `(${x})`);

        // Handle power operation (^)
        preparedFunc = preparedFunc.replace(/\(?(-?\d+)\)?\^\(?(-?\d+)\)?/g, (match, base, exponent) => {
            console.log("Base: " + base + ", exponent: " + exponent);
            return `Math.pow(${base}, ${exponent})`;
        });

        // This is still using eval, which isn't recommended for production. 
        // In a real-world scenario, you'd want to use a more secure method.
        try {
            const result = eval(preparedFunc);
            console.log("Result: " + result);
            return result;
        } catch (error) {
            console.error("Error evaluating function:", error);
            return NaN;
        }
    };

    const resetGame = () => {
        // Reset all game state
        setGuesses(Array(6).fill(Array(6).fill('')));
        setCurrentGuess(0);
        setGameWon(false);
        setMessage('');
        setPressedKey(null);
        setLastInputted({ row: -1, col: -1 });
        setInputHints([]);
        setOutputHints([]);
        setGuessResults(Array(6).fill(Array(6).fill('')));
        setCurrentHintIndex(0);
        setBlockedKeys(new Set());
        setTimeLeft(600);
    };

    return {
        guesses,
        guessResults,
        inputHints,
        outputHints,
        currentGuess,
        message,
        score,
        timeLeft,
        handleInput,
        handleDelete,
        handleSubmit,
        blockedKeys,
        correctFunction,
        gameWon,
        resetGame
    };
};